class MatrizService {
  constructor() {}
  resources = () => ({
    fecha: {
      uri: `/v1/semana`,
      method: ['get']
    },
    matriz: {
      uri: `/v1/okr/matriz`,
      method: ['get']
    },
    exportar: {
      uri: `/v1/okr/exportarmatriz`,
      method: ['get']
    },
    detalleOKR: {
      uri: `/v1/okr/detalle`,
      method: ['get']
    },
    descargarevidencia: {
      uri: `/v1/okr/downloadfile`,
      method: ['get']
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    },
    eliminar: {
      uri: `/v1/okr/eliminarOkr`,
      method: ['delete']
    },
    listJefes: {
      uri: `/v1/usuario/usuarios-modulos`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/comites`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    cargarAnios: {
      uri: `/v1/okr/listanios`,
      method: ['get']
    }
  });
}

export default MatrizService;
