<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent width="1300px" @close="close">
        <v-card>
          <v-card-title class="py-3">Ver OKR</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-6">
            <v-row class="my-1">
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field dense hide-details v-model="object.responsable" label="Responsable" readonly>
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field dense hide-details v-model="object.semanaFecha" readonly> </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <h3 class="my-2">PROGRESO - PROGRESS</h3>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-data-table
                  dense
                  :headers="cabecerasLogro"
                  :items="object.logrosSemana"
                  :items-per-page="-1"
                  class="border"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <div class="primary w-100 d-flex justify-space-between align-center pa-2">
                      <div class="white--text">¿Qué lograste esta semana? - What did you achieve this week</div>
                    </div>
                  </template>
                  <template v-slot:[`item.realizado`]="{ item }">
                    <v-chip small v-if="item.realizado" color="green" dark>
                      <span>REALIZADO</span>
                    </v-chip>
                    <v-chip small v-else color="red" dark>
                      <span>NO REALIZADO</span>
                    </v-chip>
                  </template>
                  <template v-slot:[`item.entregable`]="{ item }">
                    <v-chip small v-if="parseInt(item.subio) === 1" color="primary" dark @click="exportar(item)">
                      <v-icon color="white">mdi-cloud-download</v-icon>
                      <span :class="['ml-3']">
                        {{ item.nombrefichero }}
                      </span>
                    </v-chip>
                    <v-chip small v-else color="primary" dark>
                      <v-icon color="white">mdi-link-variant-off</v-icon>
                      <span :class="['ml-3']">SIN ENTREGABLE</span>
                    </v-chip>
                  </template>
                  <template v-slot:no-data>
                    <span>No se encontró información</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col class="py-0">
                <h3 class="my-2">PLANES - PLANS</h3>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <div>
                  <v-data-table
                    dense
                    :headers="cabecerasPlan"
                    :items="object.planes"
                    :items-per-page="-1"
                    class="border"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <div class="primary w-100 d-flex justify-space-between align-center pa-2">
                        <div class="white--text">
                          ¿Qué planeas hacer la próxima semana? - What do you plan to do next week
                        </div>
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <span>No se encontró información</span>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col class="py-0">
                <h3 class="my-2">PROBLEMAS - PROBLEMS</h3>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <div>
                  <v-data-table
                    dense
                    :headers="cabecerasProblemas"
                    :items="object.problemas"
                    :items-per-page="-1"
                    class="border"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <div class="primary w-100 d-flex justify-space-between align-center pa-2">
                        <div class="white--text">
                          ¿Qué problemas estás enfrentando? ¿Te gustaría ayuda? - What problems are you facing you would
                          like help with
                        </div>
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <span>No se encontró información</span>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" text @click="$emit('closeOKRView')">Cerrar</v-btn>
          </v-card-actions>
          <div v-if="showLoading" class="progress-background">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
export default {
  name: 'OKRView',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    object: {
      type: Object,
      default: () => ({
        idokr: '',
        item: '',
        semanaFecha: '',
        responsable: '',
        logrosSemana: [],
        planes: [],
        problemas: []
      })
    }
  },
  data: () => ({
    matrizService: null,
    cabecerasLogro: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Acción', value: 'accion', width: '300' },
      { text: 'Realizado', value: 'realizado', width: '150' },
      { text: 'Entregable', value: 'entregable', width: '250' }
    ],
    cabecerasPlan: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Acción', value: 'accion', width: '300' }
    ],
    cabecerasProblemas: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Acción', value: 'accion', width: '300' }
    ],
    showLoading: false
  }),
  methods: {
    async exportar({ index, nombrefichero }) {
      this.showLoading = true;
      const token = localStorage.getItem('token');

      await this.matrizService.downloadResource(
        'descargarevidencia',
        {
          correlativo: index,
          token: token,
          idokr: this.object.idokr
        },
        nombrefichero
      );
      this.showLoading = false;
    },
    close() {
      this.$emit('closeOKRView');
    }
  },
  async created() {
    this.matrizService = this.$httpService(new MatrizService(), this);
  }
};
</script>
