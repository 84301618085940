<template>
  <div>
    <v-row class="my-2">
      <v-col cols="10" sm="2" md="2" offset-md="2" offset-sm="2" class="d-flex align-center justify-end py-0">
        <v-select
          dense
          hide-details
          v-model="anio"
          :items="listAnios"
          @change="cambioAnio"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Año"
        ></v-select>
      </v-col>
      <!-- append-outer-icon="mdi-find-replace" @click:append-outer="listarYCargarAreas" -->
      <v-col cols="10" sm="3" md="3" class="d-flex align-center justify-end py-0 pt-1">
        <v-select
          dense
          hide-details
          v-model="semana"
          :items="semanas"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Semana"
          single-line
          multiple
          @blur="listarYCargarAreas"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="d-flex align-center py-0">
        <v-select
          dense
          hide-details
          multiple
          v-model="areas"
          :items="itemsAreas"
          @blur="initialize"
          no-data-text="No se encontró información"
          :return-object="false"
          item-text="text"
          item-value="value"
          label="Área"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllAreas" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAllAreas }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="areas.length == itemsAreas.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.value }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
      <v-col cols="2" sm="2" md="2" class="d-flex align-center justify-end py-0">
        <v-tooltip v-auth-acl="'gh-okr-okrs-export'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small class="mr-2" @click="exportarbase" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Exportar BD</span>
        </v-tooltip>
        <v-tooltip v-auth-acl="'gh-okr-okrs-notify'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="openDialogNoti" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-send</v-icon>
            </v-btn>
          </template>
          <span>Notificar OKR'S</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="okrItems"
      :height="height"
      class="border alturaokrs"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">OKR'S</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
        </div>
      </template>
      <template v-slot:[`item.codigo`]="{ item }">
        <v-chip color="primary" dark>{{ item.codigo }}</v-chip>
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-col cols="12" class="d-flex justify-center">
          <v-tooltip bottom v-if="permisoEliminar" v-auth-acl="'gh-okr-okrs-delete'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab icon x-small color="red" class="mr-2" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                <v-icon small dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-okr-okrs-view'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-1"
                fab
                icon
                x-small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="viewOKR(item.codigo)"
              >
                <v-icon small dark>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </v-col>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogSend" width="800" persistent>
      <v-card>
        <v-card-title class="py-3">Envió de notificación para responsables OKR</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="pt-8">
            <v-col cols="12" sm="12" class="py-0">
              <v-autocomplete
                dense
                v-model="responsablesOKR"
                :items="itemsResponsables"
                item-text="name"
                item-value="code"
                no-data-text="No se encontraron datos"
                label="Responsables a notificar"
                placeholder="Responsables a notificar"
                prepend-icon="mdi-database-search"
                multiple
                return-object
                @input="searchInput = null"
                :search-input.sync="searchInput"
              >
                <template v-slot:selection="data">
                  <v-chip close small @click:close="removeResOKR(data.item.code)"> {{ data.item.name }} </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field dense v-model="asunto" label="Asunto" prepend-icon="mdi-file"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-textarea dense v-model="mensaje" label="Cuerpo del mensaje" prepend-icon="mdi-file"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="closeDialog()">Cerrar</v-btn>
          <v-btn color="info" text @click="notificar()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <OKRView
      v-auth-acl="'gh-okr-okrs-view'"
      :object="objectViewOKR"
      :dialog="dialogOKRView"
      @closeOKRView="closeOKRView"
    ></OKRView>
  </div>
</template>
<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import OKRView from '../components/OKRView';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { notificateOKR } from '@/services/notificationService';
export default {
  name: 'MatrizLista',
  components: {
    OKRView
  },
  data: () => ({
    height: 180,
    semana: [],
    semanas: [],
    search: '',
    dialogSend: false,
    responsablesOKR: [],
    itemsResponsables: [],
    asunto: '',
    mensaje: '',
    permisoEliminar: false,
    showLoading: false,
    dialogOKRView: false,
    objectViewOKR: {},
    matrizService: null,
    searchInput: null,
    okrItems: [],
    headers: [
      {
        text: 'ACCIONES',
        value: 'accion',
        width: '120',
        class: 'header-items-fixed-okr nth-child1 primary',
        fixed: true
      },
      {
        text: 'RESPONSABLE OKR',
        value: 'responsable',
        width: '250',
        class: 'header-items-fixed-okr nth-child2 primary',
        fixed: true
      },
      {
        text: 'SEMANA',
        value: 'semana',
        width: '150',
        class: 'header-items-fixed-okr nth-child3 primary',
        fixed: true
      },
      { text: 'ÁREA', value: 'area', width: '200', class: 'primary' },
      { text: 'SUBAREA', value: 'subarea', width: '200', class: 'primary' },
      { text: 'CÓDIGO', value: 'codigo', width: '250', class: 'primary' },
      { text: 'CANT. ACTIVIDADES TOTALES', value: 'totales', width: '250', class: 'primary' },
      { text: 'CANT. ACTIVIDADES REALIZADAS', value: 'realizadas', width: '250', class: 'primary' },
      { text: 'CANT. ACTIVIDADES NO REALIZADAS', value: 'norealizadas', width: '250', class: 'primary' },
      { text: 'CANT. ACTIVIDADES PLANEADAS DE LA PROX. SEM.', value: 'planeadas', width: '280', class: 'primary' },
      { text: 'PORCENTAJE DE CUMPLIMIENTO', value: 'cumplimiento', width: '250', class: 'primary' },
      { text: 'FECHA CREACIÓN', value: 'fechacreacion', width: '200', class: 'primary' },
      { text: 'FECHA MODIFICACIÓN', value: 'fechamodificacion', width: '200', class: 'primary' }
    ],
    areas: [],
    itemsAreas: [],
    anio: new Date().getFullYear().toString(),
    listAnios: []
  }),
  computed: {
    checkAllAreas() {
      return (this.areas?.length ?? 0) === this.itemsAreas.length;
    },
    checkSomeAreas() {
      return (this.areas?.length ?? 0) > 0 && !this.checkAllAreas;
    },
    iconCheckboxAllAreas() {
      if (this.checkAllAreas) return 'mdi-close-box';
      if (this.checkSomeAreas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {},
  methods: {
    async listarYCargarAreas() {
      this.areas = [];
      await this.initialize();
      await this.cargarAreas();
    },
    removeResOKR(code) {
      const index = this.responsablesOKR.findIndex((el) => el.code === code);
      if (index >= 0) this.responsablesOKR.splice(index, 1);
    },
    async openDialogNoti() {
      this.showLoading = true;
      await this.listJefes();
      this.dialogSend = true;
      this.showLoading = false;
    },
    async listJefes() {
      const res = await this.matrizService.get().execResource('listJefes');
      console.log(res);
      if (res.length > 0)
        res[0].usuariosOkr.forEach((el) => {
          this.itemsResponsables.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            idsubarea: el.idsubarea,
            name: el.nombresall,
            email: el.email,
            object: {}
          });
        });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async notificar() {
      if (this.asunto == '') {
        await this.alertDialog('warning', 'Ingrese un asunto');
        return;
      }
      if (this.mensaje == '') {
        await this.alertDialog('warning', 'Ingrese un mensaje');
        return;
      }
      if (this.responsablesOKR.length <= 0) {
        await this.alertDialog('warning', 'Ingrese al menos un responsable');
        return;
      }
      this.showLoading = true;
      const resokrsaux = [];
      const participantes = [];
      let resokrNoti = [];
      let participantesNoti = [];

      this.responsablesOKR.forEach((el) => {
        resokrsaux.push(el.code);
        participantes.push(el.email);
      });
      participantesNoti = [
        {
          email: participantes,
          subject: this.asunto,
          content: this.mensaje
        }
      ];
      resokrNoti = new Set(resokrsaux);
      for (const participant of resokrNoti) {
        await notificateOKR(participant, this.mensaje);
      }
      const res = await this.matrizService.post().execResource('sendemail', {
        participantes: participantesNoti
      });
      this.showLoading = false;
      if (res.status) {
        await this.alertDialog('success', res.data);
        this.closeDialog();
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    closeDialog() {
      this.itemsResponsables = [];
      this.responsablesOKR = [];
      this.asunto = '';
      this.mensaje = '';
      this.dialogSend = false;
    },
    async deleteItem({ codigo }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Desea eliminar el okr.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const restable = await this.matrizService.delete().execResource('eliminar', { idokr: codigo });
        this.showLoading = false;
        if (restable.status) {
          await this.alertDialog('success', restable.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', restable.data);
        }
      }
    },
    async exportarbase() {
      this.showLoading = true;
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MISOKRS-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      const emp = decryptAES(localStorage.getItem('emp'));
      await this.matrizService.downloadResource(
        'exportar',
        {
          idempresa: emp,
          anio: this.anio,
          semana: this.semana,
          subarea: this.areas.toString().replaceAll('&', '%26'),
          token: token
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async initialize() {
      this.showLoading = true;
      this.okrItems = [];
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizService.get().execResource('matriz', {
        idempresa: emp,
        semana: this.semana,
        subarea: this.areas.toString().replaceAll('&', '%26'),
        anio: this.anio
      });
      if (res.length > 0) {
        res.forEach((el, index) => {
          this.okrItems.push({
            numero: index + 1,
            area: el.area,
            subarea: el.subarea,
            idsubarea: el.idsubarea,
            responsable: el.responsable,
            idresponsable: el.idresponsable,
            semana: el.semana,
            codigo: el.codigo,
            realizadas: el.actividadrealizadas,
            planeadas: el.actividadplaneadas,
            totales: parseInt(el.actividadrealizadas) + parseInt(el.actividadnorealizadas),
            cumplimiento: parseFloat(el.cumplimiento).toFixed(2) + '%',
            norealizadas: el.actividadnorealizadas,
            fechacreacion: moment(el.fechacreacion).format('DD-MM-YYYY HH:mm'),
            fechamodificacion: moment(el.fechaalter).format('DD-MM-YYYY HH:mm'),
            class: 'class-items-fixed-okr'
          });
        });
        this.height = 400;
      } else {
        this.okrItems = [];
        this.height = 180;
      }

      this.showLoading = false;
    },
    closeOKRView() {
      this.objectViewOKR = {};
      this.dialogOKRView = false;
    },
    async viewOKR(cod) {
      const logrosSemanaAux = [];
      const planesAux = [];
      const problemasAux = [];
      this.showLoading = true;
      const res = await this.matrizService.get().execResource('detalleOKR', {
        idokr: cod
      });
      this.dialogOKRView = true;
      if (res[0].tareas.length > 0) {
        res[0].tareas.forEach((element) => {
          if (element.tipo == 'NO' || element.tipo == 'SI') {
            logrosSemanaAux.push({
              id: element.item,
              index: element.correlativo,
              ficheroentregable: element.ficheroentregable,
              nombrefichero: element.nombrefichero,
              subio: element.subio,
              accion: element.descripcion,
              realizado: element.tipo == 'SI' ? true : false
            });
          }
          if (element.tipo == 'PL') {
            planesAux.push({
              index: element.correlativo,
              accion: element.descripcion
            });
          }
        });
      }
      if (res[0].problemas.length > 0) {
        res[0].problemas.forEach((el) => {
          problemasAux.push({
            index: el.item,
            accion: el.descripcion
          });
        });
      }
      this.objectViewOKR = {
        idokr: res[0].idokr,
        semanaFecha: 'SEMANA ' + res[0].semana,
        responsable: res[0].responsable,
        logrosSemana: logrosSemanaAux,
        planes: planesAux,
        problemas: problemasAux
      };
      this.showLoading = false;
    },
    async permisos() {
      const res = await this.matrizService.get().execResource('permisos');
      this.permisoEliminar = res[0].eliminarokr == 1;
    },
    async semanasItems() {
      this.showLoading = true;
      this.semana = [];
      this.semana.push(
        await this.matrizService.get().execResource('fecha', {
          fecha: this.anio == moment().year() ? moment().format('YYYY-MM-DD') : moment().year() + '-12-31'
        })
      );
      const res = await this.matrizService.get().execResource('fecha', {
        fecha: moment().year() + '-12-31'
      });
      for (let i = 1; i <= res; i++) {
        this.semanas.push(i);
      }
      this.showLoading = false;
    },
    selectAllAreas() {
      this.$nextTick(() => {
        if (this.checkAllAreas) {
          this.areas = [];
        } else {
          this.areas = this.itemsAreas.map((element) => {
            return element.value;
          });
        }
      });
    },
    async cargarAreas() {
      this.itemsAreas = [];
      this.okrItems.forEach((el) => {
        let exist = -1;
        exist = this.itemsAreas.findIndex((el2) => el2.value == el.idsubarea);
        if (exist == -1 && el.idsubarea != '') {
          this.itemsAreas.push({ value: el.idsubarea, text: el.subarea });
        }
      });
      this.itemsAreas.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      this.areas = this.itemsAreas.map((el) => el.value);
    },
    async cargarAnios() {
      this.listAnios = [];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizService.get().execResource('cargarAnios', {
        idempresa: emp
      });
      this.listAnios = res.map((el) => {
        return { value: el.anio, text: el.anio };
      });
      this.showLoading = false;
    },
    async cambioAnio() {
      await this.semanasItems();
      await this.listarYCargarAreas();
    }
  },
  async mounted() {},
  async created() {
    this.showLoading = true;
    this.matrizService = this.$httpService(new MatrizService(), this);
    await this.permisos();
    await this.cargarAnios();
    await this.semanasItems();
    await this.initialize();
    await this.cargarAreas();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.alturaokrs > .v-data-table__wrapper {
  height: calc(100vh - 420px);
}
.header-items-fixed-okr {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-okr.nth-child1 {
  top: 0;
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-okr.nth-child2 {
  left: 100px;
  top: 0;
  z-index: 4 !important;
}
.header-items-fixed-okr.nth-child3 {
  left: 350px;
  top: 0;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-okr td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-okr td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.class-items-fixed-okr td:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 350px;
  z-index: 3;
}

.cell-d-none td:last-child {
  display: none;
}

.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-okr {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-okr {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-okr td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-okr td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-okr td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-okr td:nth-child(2) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-okr td:nth-child(3) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-okr td:nth-child(3) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
